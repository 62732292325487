import React from 'react';

function SuccessAlert({icon, text}) {
    return (
        <div className="flex items-center justify-center px-4 py-2 my-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
            <i className={`${icon} text-2xl mr-2`}></i>
            <div>
                <span className="font-medium text-base">{text}</span>
            </div>
        </div>
    );
}

export default SuccessAlert;