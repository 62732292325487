import api from './api';

const URL_PREFIX = '/stripe/';

class StripeService {

  checkId(sessionId) {
    return api.get(URL_PREFIX + 'checkout-session?sessionId=' + sessionId);
  }

}

const stripeServiceInstance = new StripeService();

export default stripeServiceInstance;