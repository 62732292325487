import api from './api';

const URL_PREFIX = '/user/';

class UserService {

  getAdminBoard() {
    return api.get(URL_PREFIX + 'admin');
  }

  registerSearch(data) {
    return api.post(URL_PREFIX + 'registerSearch', data);
  }

  serverStatus() {
    return api.get(URL_PREFIX + 'serverStatus');
  }

}

const userServiceInstance = new UserService();

export default userServiceInstance;