import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "assets/svg/logo.svg"
import uk from "assets/img/flags/uk.png"
import es from "assets/img/flags/es.png"

export default function Navbar({ isViewingTallyForm }) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const navbarRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    event.preventDefault();
    const currentLanguage = i18n.language;
    if (currentLanguage === 'en') {
      i18n.changeLanguage('es');
    } else {
      i18n.changeLanguage('en');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setNavbarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* Navbar */}
      <nav ref={navbarRef} className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-blueGray-100 shadow">
        <div className="container px-0 md:px-4 mx-auto flex flex-col md:flex-row items-center justify-between">
          <div className="relative flex justify-left w-full">
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>

            {/* Brand */}
            <div
              className="w-15 cursor-pointer"
              onClick={() => navigate('/')}
            >
              <img
                alt="..."
                className="w-full min-w-140-px align-middle border-none"
                src={logo}
              />
            </div>

          </div>

          <div
            className={
              "md:flex flex-grow self-start md:self-center md:items-center bg-transparent lg:bg-opacity-0 lg:shadow-none md:w-full" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col md:flex-row list-none lg:ml-auto mt-4 md:mt-0">
              <li className="flex items-center justify-start">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold cursor-pointer"
                  href="/#howto"
                >
                  {t('navbar_howto')}
                </a>
              </li>
              <li className="flex items-center justify-start">
                <a
                  href="/#faq"
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs font-bold cursor-pointer"
                >
                  FAQs
                </a>
              </li>
              <li className="flex items-center justify-start">
                <a
                  href="/#precios"
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold cursor-pointer"
                >
                  {t('navbar_pricing')}
                </a>
              </li>
              <li className="flex items-center justify-start">
                <button
                  className="text-customBlue hover:text-sky-500 text-xs font-extrabold uppercase py-4 lg:py-2 outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 flex flex-row items-center"
                  onClick={changeLanguage}
                >
                  {i18n.language === 'es' && (
                    <><img src={uk} className="h-6 mr-2" /> Rent like a local</>
                  )}
                  {i18n.language === 'en' && (
                    <><img src={es} className="h-6 mr-2" /> Ver en español</>
                  )}
                </button>
              </li>
              {!isViewingTallyForm &&
                <li className="flex items-center">
                  <a
                    className="bg-customBlue text-white text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                    href='/#start'
                  >
                    {t('navbar_action')}
                  </a>
                </li>
              }
            </ul>
          </div>
        </div>
      </nav >
      {/* End Navbar */}
    </>
  );
}
