import React from 'react';
import CheckIcon from '../ui/CheckIcon';
import RedXIcon from '../ui/RedXIcon';
import { useTranslation } from 'react-i18next';

const PricingCardMini = ({ title, action, link, description, price, features, setIsPlanSelected }) => {
    const { t } = useTranslation();
    const handleClick = () => {
        if (price > 0) {
            window.location.href = link;
        } else {
            setIsPlanSelected(true);
        }
    };

    return (
        <div
            onClick={handleClick}
            className="cursor-pointer flex flex-col p-4 mx-auto w-full text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 dark:bg-gray-800 dark:text-white hover:border-customBlue hover:border-2">
            <div className="flex flex-row items-center justify-between w-full mb-4">
                <h3 className="text-2xl font-semibold text-left">{title}</h3>
                <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">{description}</p>
                <div className="flex justify-center items-center">
                    <span className="mr-2 text-3xl font-extrabold">{price}€</span>
                    {price > 0 &&
                        <>
                            <div className='uppercase text-xs text-gray-500 dark:text-gray-400 flex flex-col text-left'>
                                <span>{t('pricing_for')}</span>
                                <span>{t('pricing_month')}</span>
                            </div>
                        </>
                    }
                </div>
            </div>
            {/* List */}
            <ul role="list" className="space-y-2 text-left">
                {features.map((feature, index) => (
                    <li key={index} className="flex items-center space-x-3">
                        {/* Icon */}
                        {feature.isAvailable ? <CheckIcon /> : <RedXIcon />}
                        <span>{feature.text}<span className="font-semibold">{feature.detail}</span></span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PricingCardMini;