import React from 'react';
import { useTranslation } from 'react-i18next';
import PricingCard from './PricingCard';
import serverConfig from 'config/server.config';
//https://buy.stripe.com/test_4gwg2e95ygoz0uc9AE
//https://buy.stripe.com/9AQbIT1iX3a3dKEbII

const PricingSection = () => {
    const { t } = useTranslation();
    return (
        <section id='precios' className="bg-blueGray-100 dark:bg-gray-900 flex min-h-screen overflow-hidden items-center">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-lg text-center mb-8 lg:mb-12">
                    <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
                        {t('pricing_subtitle')}
                    </p>
                </div>
                <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 md:space-y-0">
                    {/* Pricing Card */}
                    <PricingCard
                        title="findeer Lite"
                        action={t('pricing_action')}
                        description={t('pricing_lite_desc')}
                        link='#start'
                        price={0}
                        features={[
                            { isAvailable: true, text: `${t('pricing_features_1')}`, detail: '' },
                            { isAvailable: true, text: `${t('pricing_features_3')}`, detail: '' },
                            { isAvailable: true, text: `${t('pricing_features_5')}`, detail: '1' },
                        ]}
                    />
                    <PricingCard
                        title="findeer Personal"
                        action={t('pricing_action')}
                        description={t('pricing_personal_desc')}
                        link={`${serverConfig.PAY_LINK}`}
                        price={serverConfig.PRICE}
                        features={[
                            { isAvailable: true, text: `${t('pricing_features_1')}`, detail: '' },
                            { isAvailable: true, text: `${t('pricing_features_2')}`, detail: '' },
                            { isAvailable: true, text: `${t('pricing_features_3')}`, detail: '' },
                            { isAvailable: true, text: `${t('pricing_features_4')}`, detail: '' },
                            { isAvailable: true, text: `${t('pricing_features_5')}`, detail: '1' },
                        ]}
                    />
                    <PricingCard
                        title="findeer Pro"
                        action={t('pricing_pro_action')}
                        description={t('pricing_pro_desc')}
                        link="mailto:hola@findeer.net"
                        price={49}
                        features={[
                            { isAvailable: true, text: `${t('pricing_features_1')}`, detail: '' },
                            { isAvailable: true, text: `${t('pricing_features_2')}`, detail: '' },
                            { isAvailable: true, text: `${t('pricing_features_3')}`, detail: '' },
                            { isAvailable: true, text: `${t('pricing_features_4')}`, detail: '' },
                            { isAvailable: true, text: `${t('pricing_features_5')}`, detail: '+10' },
                        ]}
                    />
                </div>
            </div>
        </section>
    );
};

export default PricingSection;