import React, { useRef, useEffect, useState } from 'react';
import userService from 'services/user.service';
import { useTranslation } from 'react-i18next';

function UserForm({ prevStep, handleChange, formData, setUser, setFormSubmitted }) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const handleSubmit = async () => {
        formData.lang = i18n.language;
        userService.registerSearch(formData)
            .then(response => {
                setUser(response.data);
                setFormSubmitted(true);
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <>
            <form className='my-4 px-4 w-full' onSubmit={e => { e.preventDefault(); handleSubmit(); }}>
                <button onClick={prevStep} className="text-blueGray-500 font-medium text-sm py-1.5 mb-4">
                    <i className="fas fa-arrow-left mr-1"></i> {t('form_back')}
                </button>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                        <label htmlFor="first_name" className="block mb-2 text-xl font-semibold text-blueGray-700 dark:text-white">
                            {t('form_user_name')} <span className="text-blueGray-400">*</span>
                        </label>
                        <input type="text" id="first_name" value={formData.first_name} onChange={handleChange('first_name')} className="bg-gray-50 border border-blueGray-400 shadow text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            required>
                        </input>
                    </div>
                    <div>
                        <label htmlFor="last_name" className="block mb-2 text-xl font-semibold text-blueGray-700 dark:text-white">
                            {t('form_user_surname')}
                        </label>
                        <input type="text" id="last_name" value={formData.last_name} onChange={handleChange('last_name')} className="bg-gray-50 border border-blueGray-400 shadow text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                        </input>
                    </div>
                    <div>
                        <label htmlFor="phone-input" className="block mb-2 text-xl font-semibold text-blueGray-700 dark:text-white">
                            {t('form_user_phone')} <span className="text-blueGray-400">*</span>
                        </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 start-0 top-0 flex items-center ml-2 pointer-events-none">
                                <i className="fas fa-phone-alt text-gray-400"></i>
                            </div>
                            <input
                                type="tel"
                                id="phone-input"
                                aria-describedby="helper-text-explanation"
                                value={formData.phone}
                                onChange={handleChange('phone')}
                                className="bg-gray-50 border border-blueGray-400 shadow text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-8 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="+34 612 345 678"
                                required
                                pattern="^\+?[1-9]\d{1,14}(?:\s*\d{1,14})*$"
                                title="+34 612 345 678"

                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="email-input" className="block mb-2 text-xl font-semibold text-blueGray-700 dark:text-white">
                            {t('form_user_email')} <span className="text-blueGray-400">*</span>
                        </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 start-0 top-0 flex items-center ml-2 pointer-events-none">
                                <i className="fas fa-at text-xl text-gray-400"></i>
                            </div>
                            <input
                                type="email"
                                id="email-input"
                                aria-describedby="helper-text-explanation"
                                className="min-h-42 bg-gray-50 border border-blueGray-400 shadow text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-8 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={formData.email}
                                onChange={handleChange('email')}
                                placeholder="hola@findeer.net"
                                required
                                pattern="^[a-zA-Z0-9._%+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                                title="hola@findeer.net"
                            />
                        </div>
                    </div>
                </div>
                <div className="flex items-center mt-10">
                    <input
                        id="link-checkbox"
                        type="checkbox"
                        required
                        value=""
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                        htmlFor="link-checkbox"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        {t('form_user_consent')} <a href="/privacidad" target="_blank" rel="noopener noreferrer" className="text-blue-600 dark:text-blue-500 hover:underline">{t('form_user_consent_link')}</a> {t('form_user_consent_connect')} <a href="/terminos-condiciones" target="_blank" rel="noopener noreferrer" className="text-blue-600 dark:text-blue-500 hover:underline">{t('form_user_consent_link2')}</a>
                    </label>
                </div>
                <button type="submit" className="text-white bg-customBlue hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-4 py-1.5 mt-6 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Activar búsqueda automática <i className="fas fa-arrow-right ml-2"></i>
                </button>
            </form>
        </>
    );
}

export default UserForm;