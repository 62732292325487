import React, { useEffect, useState, useRef } from 'react';
import PricingSectionMini from '../Pricing/PricingSectionMini';
import MessengerActivation from './MessengerActivation';
import InformationalText from '../ui/InformationalText';
import SuccessAlert from '../ui/SuccessAlert';
import SearchForm from './SearchForm';
import userService from 'services/user.service';
import { useTranslation } from 'react-i18next';

function TallyForm({ searchActive, savedUser, formLink }) {
  const { t } = useTranslation();
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [isPlanSelected, setIsPlanSelected] = useState(false);
  const [serverStatus, setServerStatus] = useState(true);
  const [user, setUser] = useState({});
  const [hideAlert, setHideAlert] = useState(false);

  useEffect(() => {
    if (window.Tally) {
      window.Tally.loadEmbeds();
    }

    window.addEventListener('message', handleTallyEvent);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleTallyEvent);
    };
  }, [serverStatus]);

  useEffect(() => {
    userService.serverStatus()
      .then(response => {
        if (response.status === 200) {
          setServerStatus(true);
        } else {
          setServerStatus(false);
        }
      })
      .catch(error => {
        console.log(error);
        setServerStatus(false);
      });
  }, []);


  const handleTallyEvent = (e) => {
    if (e.origin === 'https://tally.so' && e.data?.includes('event')) {
      const data = JSON.parse(e.data);
      if (data.event === 'Tally.FormSubmitted') {
        const payload = data.payload;
        const userData = {};
        const nombreField = payload.fields.find(field => field.title === 'Nombre');
        const mailField = payload.fields.find(field => field.title === 'Correo electrónico');
        userData.name = nombreField ? nombreField.answer.value : '';
        userData.email = mailField ? mailField.answer.value : '';
        userData.respondentId = payload.respondentId;
        userData.submissionId = payload.id;
        userData.link = `https://t.me/findeerbot?start=${payload.submissionId}`;
        setUser(userData);
        setFormSubmitted(true);
      } else if (data.event === 'Tally.FormLoaded') {
        const payload = data.payload;
        // ...
      }
    }
  };

  useEffect(() => {
    if (searchActive) {
      setUser(savedUser);
      setFormSubmitted(true);
      setIsPlanSelected(true);
    } else if (!searchActive && savedUser?.sessionId && !savedUser?.submissionId) {
      setUser(savedUser);
      setIsPlanSelected(true);
    }
  }, [searchActive, savedUser]);

  return (
    <div className="container mx-auto md:px-4 h-full">
      <div className="flex flex-col content-center items-center justify-center h-full">
        <div className="relative flex flex-col items-center justify-center min-w-0 break-words w-full p-2 md:px-6 md:py-4 shadow-lg rounded-lg bg-blueGray-100 border-0">
          {isFormSubmitted ? (
            <>
              {isPlanSelected ? (
                <MessengerActivation user={user} />
              ) : (
                <PricingSectionMini user={user} setIsPlanSelected={setIsPlanSelected} />
              )}
            </>
          ) : (
            <>
              {user && user.email && !hideAlert && (
                <>
                  <SuccessAlert icon={'fas fa-check-circle'} text={t('form_success')} />
                  <div className="flex flex-col items-center justify-center mb-2">
                    <p className="text-3xl font-semibold text-blueGray-700 pb-2">
                      {t('form_welcome')}
                    </p>
                    <p className="text-base text-center font-light text-blueGray-500">
                      {t('form_welcome_subtitle')}
                    </p>
                  </div>
                </>
              )}
              {serverStatus === true ? (

                <SearchForm user={user} setUser={setUser} setFormSubmitted={setFormSubmitted} setHideAlert={setHideAlert}/>
              ) : (
                <iframe
                  data-tally-src={formLink}
                  loading="lazy"
                  width="100%"
                  height="400"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                  title="Tally Form"
                ></iframe>
              )}
              {/*
              <InformationalText />
              */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TallyForm;