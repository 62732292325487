import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// layouts


// views without layouts


import Index from "views/Index.js";
import NotFound from "components/Own/404/NotFound";
import articleRoutes from "articles/articleRoutes";
import ArticlePage from "pages/ArticlePage";
import Terms from "./views/Terms";
import Privacy from "./views/Privacy";

function App() {

    return (
        <>
            <Routes>
                {/* add routes with layouts */}

                {/* add routes without layouts */}
                <Route path="/" element={<Index />} />
                <Route path="/en" element={<Index lng={'en'} />} />
                <Route path="/gracias" element={<Index />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="/terminos-condiciones" element={<Terms />} />
                <Route path="/privacidad" element={<Privacy />} />
                {Object.keys(articleRoutes).map((path) => (
                    <Route key={path} path={`/blog/${path}`} element={<ArticlePage contentUrl={articleRoutes[path]} />} />
                ))}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </>
    );
}

export default App;