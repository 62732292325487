import React, { useRef, useEffect, useState } from 'react';
import PropertyForm from './PropertyForm';
import UserForm from './UserForm';
import AreaForm from './AreaForm';
import ExtrasForm from './ExtrasForm';


function SearchForm({ user, setUser, setFormSubmitted, setHideAlert }) {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({ 'lang': '', 'transactionType': 'comprar', 'price': '', 'surface': '', 'rooms': 3, 'bathrooms': 2, "first_name": '', "last_name": '', "phone": '', "email": '', 'stripeSessionId': '', 'extras': [] });

    useEffect(() => {
        if (user.email && user.sessionId) {
            setFormData({ ...formData, "email": user.email, 'stripeSessionId': user.sessionId });
        }
    }, [user]);

    const nextStep = () => {
        setHideAlert(true);
        setStep(prevStep => prevStep + 1);
    };

    const prevStep = () => {
        setStep(prevStep => prevStep - 1);
    };

    const handleChange = input => e => {
        let value = e.target.value;
        if (input === 'phone') {
            value = formatPhoneNumber(value);
        }
        if (input === 'email') {
            value = value.trim().toLowerCase();
        }
        setFormData({ ...formData, [input]: value });
    };

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/[^\d+]/g, '');
        var formatted = '';
        if (cleaned.startsWith('34')) {
            cleaned = cleaned.slice(2);
            formatted = '+34 ';
        } else if (!phoneNumberString.startsWith('+') && cleaned.length >= 9) {
            formatted = '+34 ';
        }
        while (cleaned.length > 3) {
            formatted += cleaned.slice(0, 3) + ' ';
            cleaned = cleaned.slice(3);
        }
        formatted += cleaned;
        return formatted;
    }

    switch (step) {
        case 1:
            return <PropertyForm nextStep={nextStep} handleChange={handleChange} formData={formData} />;
        case 2:
            return <ExtrasForm nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />;
        case 3:
            return <AreaForm nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} setFormData={setFormData} />;
        case 4:
            return <UserForm prevStep={prevStep} handleChange={handleChange} formData={formData} setUser={setUser} setFormSubmitted={setFormSubmitted} />;
        default:
            return null;
    }
}

export default SearchForm;