import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function InformationalText() {
  const { t } = useTranslation();
  const [showPopover, setShowPopover] = useState(false);

  const handleButtonClick = () => {
    setShowPopover(!showPopover);
  };

  const handleMouseEnter = () => {
    setShowPopover(true);
  };

  const handleMouseLeave = () => {
    setShowPopover(false);
  };

  return (
    <div className="relative w-full mb-2"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <p onClick={handleButtonClick}
        className="flex items-center cursor-pointer text-base font-semibold pt-4 pb-2">
        <i className="fas fa-info-circle text-lg mr-2"></i>
        {t('form_tips_title')}
      </p>
      {showPopover && (
        <div data-popover
          id="popover-description"
          role="tooltip"
          className="absolute bottom-full left-1/2 transform-2 -translate-x-1/2 z-10 w-full text-base text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
          <div className="p-3 space-y-2">
            <p className="font-bold pt-2 pb-1">
              {t('form_tips_1')}
            </p>
            <p>
              {t('form_tips_2')}
            </p>
            <p>
              {t('form_tips_3')}
            </p>
            <p className="font-light font-italic">
              {t('form_tips_4')}
            </p>
            <a href="#faq" className="flex items-center font-medium text-customBlue text-sm pt-1 hover:underline">
              {t('form_tips_5')}
              <svg className="w-2 h-2 ml-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"></path>
              </svg>
            </a>
          </div>
          <div data-popper-arrow></div>
        </div>
      )}
    </div>
  );
}

export default InformationalText;