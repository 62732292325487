import sample from './files/sample.md';
import article1 from './files/encuentra-piso-barcelona.md';
import article2 from './files/consejos-alquiler-barato-barcelona-2024.md';
// import other articles here...

const articleRoutes = {
  'sample': sample,
  'encuentra-piso-barcelona': article1,
  'consejos-alquiler-barato-barcelona-2024': article2,
  // add other articles here...
};

export default articleRoutes;