import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import completeGeojsonData from 'assets/geoJson/all.json';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css'
import { useTranslation } from 'react-i18next';

// Fix the default marker icon issue when using Leaflet with Webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function AreaForm({ prevStep, nextStep, handleChange, formData, setFormData }) {
    const { t } = useTranslation();
    const [showError, setShowError] = useState(false);
    const position = [41.3851, 2.1734]; // Latitude and Longitude of Barcelona
    const [map, setMap] = useState(null);
    const [geojsonData, setGeojsonData] = useState(completeGeojsonData);
    const geoJsonRef = useRef();

    const defaultStyle = {
        color: "#18216D",
        weight: 2,
        opacity: 1,
    };

    const selectedStyle = {
        color: '#ff7800',
        weight: 4,
        opacity: 1
    };

    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const selectedFeaturesRef = useRef(selectedFeatures); // Create a ref

    useEffect(() => {
        // Filter the GeoJSON data
        const filteredGeojsonData = {
            ...completeGeojsonData,
            features: completeGeojsonData.features.filter(feature => feature.properties.admin_level === 9)
        };

        // Update the GeoJSON data
        setGeojsonData(filteredGeojsonData);
    }, []);

    // Update the ref whenever selectedFeatures changes
    useEffect(() => {
        selectedFeaturesRef.current = selectedFeatures;
    }, [selectedFeatures]);

    const onEachFeature = (feature, layer) => {
        // Add a tooltip to the layer
        layer.bindTooltip(feature.properties.name, {
            sticky: false
        });
        let clickTimeout = null;
        layer.on('click', () => {
            if (clickTimeout === null) {
                clickTimeout = setTimeout(() => {
                    const featureId = feature.properties.relationId;
                    setSelectedFeatures(prevSelectedFeatures => {
                        const index = prevSelectedFeatures.indexOf(featureId);
                        if (index === -1) {
                            // If the clicked feature is an admin_level 9 area, add the relationId of all its children to the array
                            if (feature.properties.admin_level === 9) {
                                const childrenIds = completeGeojsonData.features
                                    .filter(nextFeature => nextFeature.properties.parent === featureId)
                                    .map(childFeature => childFeature.properties.relationId);
                                return [...prevSelectedFeatures, featureId, ...childrenIds];
                            } else {
                                return [...prevSelectedFeatures, featureId];
                            }
                        } else {
                            // If the clicked feature is an admin_level 9 area, remove the relationId of all its children from the array
                            if (feature.properties.admin_level === 9) {
                                const childrenIds = completeGeojsonData.features
                                    .filter(nextFeature => nextFeature.properties.parent === featureId)
                                    .map(childFeature => childFeature.properties.relationId);
                                return prevSelectedFeatures.filter(id => id !== featureId && !childrenIds.includes(id));
                            } else {
                                return prevSelectedFeatures.filter(id => id !== featureId);
                            }
                        }
                    });
                    clickTimeout = null; // Reset the timeout
                }, 250); // Delay for 250ms
            }
        });
        layer.on('dblclick', () => {
            if (clickTimeout !== null) {
                clearTimeout(clickTimeout); // Cancel the 'click' event handler
                clickTimeout = null; // Reset the timeout

                // Check if the double clicked feature is an admin_level 9 area
                if (feature.properties.admin_level === 9) {
                    // Filter out the double clicked feature
                    const newFeatures = geojsonData.features.filter(nextFeature => nextFeature.properties.relationId !== feature.properties.relationId);

                    // Add the admin_level 10 areas corresponding to the double clicked feature
                    const level10Features = completeGeojsonData.features.filter(nextFeature => nextFeature.properties.parent === feature.properties.relationId);
                    newFeatures.push(...level10Features);

                    // Update the GeoJSON data
                    setGeojsonData({
                        ...geojsonData,
                        features: newFeatures
                    });
                }
            }
        });
    };

    useEffect(() => {
        if (!geoJsonRef.current) return; // Check if the GeoJSON layer has been created (it's null when the component mounts)
        geoJsonRef.current.resetStyle();
        // Loop through all layers in the GeoJSON layer group
        geoJsonRef.current.eachLayer(layer => {
            // If the layer's feature.properties.relationId matches any of the selectedRelationIds, set its style to selectedStyle
            if (selectedFeatures.includes(layer.feature.properties.relationId)) {
                layer.setStyle(selectedStyle);
            } else {
                layer.setStyle(defaultStyle);
            }
        });
    }, [selectedFeatures, selectedStyle, defaultStyle, geojsonData]);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Create the inputDistricts and inputNeighborhoods arrays
        const inputDistricts = [];
        const inputNeighborhoods = [];

        // Loop through all selected features
        selectedFeatures.forEach(featureId => {
            // Find the feature with the current featureId
            const feature = completeGeojsonData.features.find(f => f.properties.relationId === featureId);

            // Check the admin_level of the feature and add its name to the corresponding array
            if (feature.properties.admin_level === 9) {
                inputDistricts.push(feature.properties.name);
            } else if (feature.properties.admin_level === 10) {
                inputNeighborhoods.push(feature.properties.name);
            }
        });

        // Update formData with the new arrays
        setFormData(prevFormData => ({
            ...prevFormData,
            districts: inputDistricts,
            neighborhoods: inputNeighborhoods
        }));

        if (inputDistricts.length === 0) {
            setShowError(true);
            return;
        }

        nextStep();
    };


    return (
        <form className="my-4 px-4 w-full" onSubmit={handleSubmit}>
            <button onClick={prevStep} className="text-blueGray-500 font-medium text-sm py-1.5 mb-4">
                <i className="fas fa-arrow-left mr-1"></i> {t('form_back')}
            </button>

            <label className="block mb-3 text-xl font-semibold text-blueGray-700 dark:text-white">
                {t('form_question_area')}
            </label>

            <MapContainer
                className='mb-3 z-2'
                whenCreated={setMap}
                center={position}
                zoom={11}
                style={{ height: '300px', width: '100%' }}
                doubleClickZoom={true}
            >
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>'
                />
                <GeoJSON
                    key={geojsonData.features.length}
                    ref={geoJsonRef}
                    data={geojsonData}
                    onEachFeature={onEachFeature}
                    style={defaultStyle} />

                <FullscreenControl position="topleft" />

            </MapContainer>

            <p className="mb-2 text-base font-italic text-blueGray-700 dark:text-white">
                <i className="far fa-hand-pointer text-lg mr-2"></i>
                {t('form_area_hint')}
            </p>

            {showError && <p className='text-red-500 text-sm'>{t('form_area_error')}</p>}

            <button type="submit" className="text-white bg-customBlue hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-4 py-1.5 mt-6 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                {t('form_area_action')} <i className="fas fa-arrow-right ml-2"></i>
            </button>
        </form>
    );
}

export default AreaForm;