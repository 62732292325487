import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function PropertyForm({ nextStep, handleChange, formData }) {
  const { t } = useTranslation();
  return (
    <form className="my-4 px-4 w-full" onSubmit={e => { e.preventDefault(); nextStep(); }}>
      <div className='mb-6 w-10/12'>
        <label className="block mb-3 text-xl font-semibold text-blueGray-700 dark:text-white">
          {t('form_question_type')}
        </label>
        <ul className="flex flex-col md:flex-row w-full gap-4 md:gap-6">
          <li>
            <input
              type="radio"
              id="comprar"
              name="transaction"
              value="comprar"
              className="hidden peer"
              required
              checked={formData.transactionType === 'comprar'}
              onChange={handleChange('transactionType')}
            />
            <label htmlFor="comprar" className="block text-center min-w-140-px py-1.5 px-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-blueGray-400 focus:ring-customBlue focus:border-customBlue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:border-blue-500 shadow cursor-pointer peer-checked:border-blue-600">
              {t('form_question_type_buy')}
            </label>
          </li>
          <li>
            <input
              type="radio"
              id="alquilar"
              name="transaction"
              value="alquilar"
              className="hidden peer"
              checked={formData.transactionType === 'alquilar'}
              onChange={handleChange('transactionType')}
            />
            <label htmlFor="alquilar" className="block text-center min-w-140-px py-1.5 px-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-blueGray-400 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:border-blue-500 shadow cursor-pointer peer-checked:border-blue-600">
              {t('form_question_type_rent')}
            </label>
          </li>
        </ul>
      </div>

      <div className='flex flex-col md:flex-row justify-between' >
        <div className='w-full mb-6 md:mr-4'>
          <div className="w-10/12">
            <label htmlFor="price-input" className="block mb-2 text-xl font-semibold text-blueGray-700 dark:text-white">
              {t('form_question_price')}
            </label>
            <input type="number" id="price-input" value={formData.price} onChange={handleChange('price')} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-blueGray-400 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:border-blue-500 shadow"
              placeholder={`${formData.transactionType === 'comprar' ? '€' : '€/mes'}`}
              required />
          </div>
        </div>

        <div className='w-full mb-6 md:ml-4'>
          <div className="w-10/12">
            <label htmlFor="surface-input" className="block mb-2 text-xl font-semibold text-blueGray-700 dark:text-white">
              {t('form_question_surface')}
            </label>
            <input type="number" id="surface-input" value={formData.surface} onChange={handleChange('surface')} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-blueGray-400 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:border-blue-500 shadow"
              placeholder="m²"
              required />
          </div>
        </div>
      </div>


      <div className='flex flex-col md:flex-row justify-between'>
        <div className='w-full mb-6 md:mr-4'>
          <div className="relative w-10/12">
            <label htmlFor="rooms-range-input" className="block mb-2 text-xl font-semibold text-blueGray-700 dark:text-white">
              {t('form_question_rooms')}
            </label>
            <input id="rooms-range-input" type="range" value={formData.rooms} onChange={handleChange('rooms')} min={1} max={5} step={1} className="w-full h-1.5 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />
            <div className="flex justify-between text-sm text-gray-500 dark:text-gray-400 -mb-6 mt-2">
              {[1, 2, 3, 4, "5+"].map((label, index, array) => (
                <span
                  className='w-6 text-center'
                  key={index}
                >
                  {label}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className='w-full mb-6 md:ml-4'>
          <div className="relative w-10/12">
            <label htmlFor="bathrooms-range-input" className="block mb-2 text-xl font-semibold text-blueGray-700 dark:text-white">
              {t('form_question_bathrooms')}
            </label>
            <input id="bathrooms-range-input" type="range" value={formData.bathrooms} onChange={handleChange('bathrooms')} min={1} max={5} step={1} className="w-full h-1.5 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />
            <div className="flex justify-between text-sm text-gray-500 dark:text-gray-400 -mb-6 mt-2">
              {[1, 2, 3, 4, "5+"].map((label, index, array) => (
                <span
                  className='w-6 text-center'
                  key={index}
                >
                  {label}
                </span>
              ))}
            </div>
          </div>
        </div>

      </div>

      <button type="submit" className="text-white bg-customBlue hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-4 py-1.5 mt-6 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        {t('form_property_action')} <i className="fas fa-arrow-right ml-2"></i>
      </button>
    </form>
  );
}

export default PropertyForm;