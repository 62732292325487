/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import i18n from "i18next";

import findeer from "assets/img/findeer_mirror.png";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import TallyForm from "components/Own/Form/TallyForm";
import PricingSection from "components/Own/Pricing/PricingSection";
import Faq from "components/Own/FAQ/Faq";

import criteriaGif from "assets/vids/criteria.gif";
import messagePng from "assets/img/automatic_message.png";
import notificationPng from "assets/img/findeer_notification.png";

import stripeService from "services/stripe.service";
import Footer from "components/Footers/Footer";

export default function Index({ lng = 'es' }) {
  const { t } = useTranslation();

  const tallyFormRef = useRef(null);
  const gifRef = useRef();

  const [isViewingTallyForm, setIsViewingTallyForm] = useState(false);
  const [savedUser, setSavedUser] = useState({});
  const [searchActive, setSearchActive] = useState(false);
  const [formLink, setFormLink] = useState('');
  const [showBanner, setShowBanner] = useState(true);

  function closeBanner() {
    setShowBanner(false);
  }

  useEffect(() => {
    if (lng) {
      i18n.changeLanguage(lng);
    }
    const sessionId = new URLSearchParams(window.location.search).get('id');
    if (sessionId) {
      closeBanner();
      stripeService.checkId(sessionId)
        .then(response => {
          const data = response.data;
          setFormLink(`https://tally.so/embed/w2Kqze?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&email=${encodeURIComponent(data?.user?.email || '')}&sessionId=${data?.user?.sessionId || ''}`);
          setSavedUser(data?.user);
          if (data?.searchActive) {
            setSearchActive(true);
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      setFormLink(`https://tally.so/embed/w2Kqze?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1`);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const { isIntersecting } = entry;
        if (isIntersecting) {
          gifRef.current.src = criteriaGif;
          return; // if we see the target, stop observing
        }
      });
    });

    observer.observe(gifRef.current);

    // Clean up on unmount
    return () => observer.unobserve(gifRef.current);
  }, []);

  useEffect(() => {
    const checkIfViewingTallyForm = () => {
      const rect = tallyFormRef.current.getBoundingClientRect();
      setIsViewingTallyForm(rect.top >= 0 && rect.bottom <= window.innerHeight);
    };

    window.addEventListener('scroll', checkIfViewingTallyForm);
    checkIfViewingTallyForm(); // check initially

    return () => {
      // cleanup - remove the event listener when the component unmounts
      window.removeEventListener('scroll', checkIfViewingTallyForm);
    };
  }, []);

  return (
    <>
      <AdminNavbar fixed isViewingTallyForm={isViewingTallyForm} />

      {showBanner && (
        <div id="banner" tabIndex="-1" className="flex fixed z-50 justify-center items-start py-2 px-4 md:px-16 w-full bg-gray-50 border border-b border-gray-200 sm:items-center dark:border-gray-700 dark:bg-gray-800">
          <div className="container flex flex-row">
            <p className="flex flex-col md:flex-row  text-sm w-full font-light text-gray-500 dark:text-gray-400">
              <span className="font-bold text-base text-red-700 flex items-center" >
                {t('promo_banner_title')}
              </span>
              <span className="text-base md:flex md:items-center md:ml-2" >
                {t('promo_banner_subtitle')} <span className="font-semibold ml-1" >FINDEER50</span>
              </span>
            </p>
            <button onClick={closeBanner} type="button" className="flex items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
          </div>
        </div>
      )}

      <section id="start" className="header relative pt-24 items-center flex flex-col md:flex-row min-h-screen md:h-screen xxl:h-auto xxl:min-h-0 py-10 xxl:pt-48 xxl:pb-40 overflow-hidden">
        <div className="absolute inset-0 bg-blueGray-200"></div>
        <div className="container z-4 mx-auto flex flex-col md:flex-row h-full items-center">
          <div className="container z-2 px-4 w-full md:w-6/12 items-center flex flex-wrap h-full">
            <div className="">
              <h2 className="font-semibold text-center pt-6 md:pt-0 md:text-left text-4xl md:text-6xl mb-10">
                {t('landing_title')}
              </h2>
              <p className="mt-4 text-lg leading-relaxed text-left text-blueGray-700">
                <span className="font-semibold">{t('point_1_bold')}</span> {t('point_1')}
              </p>
              <p className="mt-4 text-lg leading-relaxed text-left text-blueGray-700">
                <span className="font-semibold">{t('point_2_bold')}</span> {t('point_2')}
              </p>
              <p className="mt-4 text-lg leading-relaxed text-left text-blueGray-700">
                <span className="font-semibold">{t('point_3_bold')}</span> {t('point_3')}
              </p>
              <p className="mt-4 text-lg leading-relaxed text-left text-blueGray-700">
                <span className="font-semibold">{t('point_4_bold')}</span> {t('point_4')}
              </p>
              <p className="mt-4 mb-4 text-lg leading-relaxed text-left text-blueGray-700">
                <span className="font-semibold">{t('point_5_bold')}</span> {t('point_5')}
              </p>
            </div>
            {/*}
            <img
              className="absolute z-0 h-7/10 max-h-860-px self-end hidden md:block"
              src={findeer}
              alt="findeer"
            />
  */}
          </div>

          <div className="relative w-full h-full md:w-6/12 p-4 flex items-center justify-end">
            <div className="container z-4 justify-end md:w-11/12" ref={tallyFormRef}>
              {formLink && (
                <>
                  <TallyForm savedUser={savedUser} searchActive={searchActive} formLink={formLink} />
                </>
              )}
            </div>
          </div>
        </div>

      </section>

      <section id="howto" className="header relative pt-16 items-center flex flex-col overflow-hidden">
        <div className="absolute inset-0 bg-blueGray-100"></div>
        <div className="container z-4 mx-auto flex flex-col-reverse md:flex-row h-full items-center pt-6">
          <div className="container z-2 px-4 w-full md:w-4/12 items-center flex flex-wrap h-full">
            <img className="object-fill w-100 aspect-075 shadow-lg" ref={gifRef} alt="Criteria selection" />
          </div>
          <div className="container z-2 px-4 md:px-12 w-full md:w-8/12 items-center justify-center flex flex-wrap h-full">
            <div className="pt-6 pb-6">
              <h2 className="font-semibold text-center md:text-left text-4xl">
                {t('howto_1_title')}
              </h2>
              <p className="mt-4 text-xl leading-relaxed text-blueGray-700">
                {t('howto_1_text')}
                <br />
                {t('howto_1_text_2')}
              </p>
            </div>
          </div>
        </div>
        <div className="container z-4 mx-auto flex flex-col md:flex-row h-full items-center py-16 md:py-40">
          <div className="container z-2 px-4 md:px-12 w-full md:w-6/12 items-center justify-center  flex flex-wrap h-full">
            <div className="pt-6 pb-6">
              <h2 className="font-semibold text-center md:text-left text-4xl">
                {t('howto_2_title')}
              </h2>
              <p className="mt-4 text-xl leading-relaxed text-blueGray-700">
                {t('howto_2_text')}
                <br />
                {t('howto_2_text_2')}
              </p>
            </div>
          </div>
          <div className="container z-2 px-4 w-full md:w-6/12 items-center flex flex-wrap h-full">
            <img className="object-fill w-100 shadow-lg" src={messagePng} alt="Automatic messages" />
          </div>
        </div>
        <div className="container z-4 mx-auto flex flex-col-reverse md:flex-row h-full items-center">
          <div className="container z-2 px-4 w-full md:w-4/12 items-center flex flex-wrap h-full">
            <img className="object-fill w-100" src={notificationPng} alt="Notifications" />
          </div>
          <div className="container z-2 px-4 md:px-12 w-full md:w-8/12 items-center justify-center flex flex-wrap h-full">
            <div className="pt-6 pb-6">
              <h2 className="font-semibold text-center md:text-left text-4xl">
                {t('howto_3_title')}
              </h2>
              <p className="mt-4 text-xl leading-relaxed text-blueGray-700">
                {t('howto_3_text')}
                <br />
                {t('howto_3_text_2')}
              </p>
            </div>
          </div>
        </div>

      </section>

      <section id="faq" className="bg-blueGray-200">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 pb-32">
          <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            FAQs
          </h2>
          <Faq />
        </div>
      </section>

      <PricingSection />
      <Footer />
    </>
  );
}
