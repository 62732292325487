import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from 'components/Footers/Footer';

const ArticlePage = ({ contentUrl }) => {
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch(contentUrl)
            .then((response) => response.text())
            .then((text) => {
                setContent(text.replace(/\\n/g, "\n"))
            });
    }, [contentUrl]);

    return (
        <>
            <AdminNavbar fixed />
            <section id="start" className="container pt-16 pb-40 items-center min-h-screen prose lg:prose-xl mx-auto p-4 min-h-screen overflow-hidden">
                <ReactMarkdown
                className='line-break markdown-body'
                >{content}</ReactMarkdown>
            </section >
            <Footer />
        </>
    );
};

export default ArticlePage;