import React, { useEffect } from 'react';
import PricingCardMini from './PricingCardMini';
import serverConfig from 'config/server.config';
import { useTranslation } from 'react-i18next';
//https://buy.stripe.com/test_4gwg2e95ygoz0uc9AE
//https://buy.stripe.com/9AQbIT1iX3a3dKEbII

const PricingSectionMini = ({ setIsPlanSelected, user }) => {
    const { t } = useTranslation();
    useEffect(() => {
        console.log('PricingSectionMini useEffect');
        console.log(user);
    }, [user]);
    return (
        <div className="py-2 px-2 mx-auto w-full">
            <p className="text-3xl font-semibold pb-10 text-center">
                Elige tu plan
            </p>
            <div className="space-y-8 sm:gap-6">
                {/* Pricing Card */}
                <PricingCardMini setIsPlanSelected={setIsPlanSelected}
                    title="findeer Lite"
                    action={t('pricing_action')}
                    description=""
                    link=''
                    price={0}
                    features={[
                        { isAvailable: true, text: `${t('pricing_features_1')}`, detail: '' },
                        { isAvailable: true, text: `${t('pricing_features_3')}`, detail: '' },
                        { isAvailable: true, text: `${t('pricing_features_5')}`, detail: '1' },
                    ]}
                />
                <PricingCardMini setIsPlanSelected={setIsPlanSelected}
                    title="findeer Personal"
                    action={t('pricing_action')}
                    description=""
                    link={`${serverConfig.PAY_LINK}?prefilled_email=${encodeURIComponent(user?.email)}&client_reference_id=${user?.submissionId}`}
                    price={serverConfig.PRICE}
                    features={[
                        { isAvailable: true, text: `${t('pricing_features_1')}`, detail: '' },
                        { isAvailable: true, text: `${t('pricing_features_2')}`, detail: '' },
                        { isAvailable: true, text: `${t('pricing_features_3')}`, detail: '' },
                        { isAvailable: true, text: `${t('pricing_features_4')}`, detail: '' },
                        { isAvailable: true, text: `${t('pricing_features_5')}`, detail: '1' },
                    ]}
                />
            </div>
        </div>
    );
};

export default PricingSectionMini;