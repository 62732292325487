import React from 'react';
import CheckIcon from '../ui/CheckIcon';
import RedXIcon from '../ui/RedXIcon';
import { useTranslation } from 'react-i18next';

const PricingCard = ({ title, action, link, description, price, features }) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white w-full">
            <h3 className="mb-4 text-2xl font-semibold">{title}</h3>
            <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">{description}</p>
            <div className="flex justify-center items-center my-8">
                <span className="mr-2 text-5xl font-extrabold">{price}€</span>
                {price > 0 &&
                    <>
                        <div className='uppercase text-xs text-gray-500 dark:text-gray-400 flex flex-col text-left pt-2'>
                            <span>{t('pricing_for')}</span>
                            <span>{t('pricing_4ever')}</span>
                        </div>
                    </>
                }
            </div>
            <a href={link} className="text-white bg-customBlue hover:bg-sky-700 focus:ring-4 focus:ring-sky-200 mb-8 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-sky-900">
                {action}
            </a>
            {/* List */}
            <ul role="list" className="mb-8 space-y-4 text-left">
                {features.map((feature, index) => (
                    <li key={index} className="flex items-center space-x-3">
                        {/* Icon */}
                        {feature.isAvailable ? <CheckIcon /> : <RedXIcon />}
                        <span>{feature.text}<span className="font-semibold">{feature.detail}</span></span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PricingCard;