import React, { useRef, useEffect, useState } from 'react';
import QRCode from "qrcode";
import SuccessAlert from '../ui/SuccessAlert';
import { useTranslation } from 'react-i18next';

function MessengerActivation({ user }) {
    const { t } = useTranslation();
    const canvasRef = useRef(null);
    const [link, setLink] = useState('');

    useEffect(() => {
        if (!isEmpty(user)) {
            console.log('user', user);
            setLink(`https://t.me/findeerbot?start=${user.submissionId}`);
        }
    }, [user]);

    useEffect(() => {
        if (link) {
            generateQRCode(link);
        }
    }, [link]);

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    const generateQRCode = (link) => {
        QRCode.toCanvas(
            canvasRef.current,
            link,
            {
                errorCorrectionLevel: 'L',
                width: 300,
            },
            (error) => error && console.error(error)
        );
    };

    return (
        <>
            <p className="text-2xl font-semibold text-center mb-4 leading-relaxed text-blueGray-700">
                {t('messenger_thanks')} {user?.name}!
            </p>
            <p className="text-lg leading-relaxed text-center mb-8 text-blueGray-700">
                {user?.isPremium ?
                    (user?.isWhatsAppActive ?
                        <>
                            <SuccessAlert icon={'fab fa-whatsapp'} text={t('messenger_success')} />
                            {t('messenger_text_premium_wa')}
                            <br />
                            <br />
                            {t('messenger_text_premium_wa_2')}
                        </>
                        :
                        <>
                            {t('messenger_text_premium')}
                            <br />
                            <br />
                            {t('messenger_text_premium_2')}
                        </>
                    )
                    :
                    (
                        <>
                            {t('messenger_text_lite')}
                        </>
                    )
                }
            </p>
            <canvas
                className='cursor-pointer justify-center flex mb-4 h-auto-important max-w-full'
                ref={canvasRef}
            />
            <a href={link}>
                <button
                    type="button"
                    className="text-white bg-telegram-color hover:bg-telegram-color font-medium rounded-lg text-base px-3 py-2.5 text-center inline-flex items-center me-2 mb-2 mt-4"
                >
                    <i className="fas fa-paper-plane mr-2"></i>
                    {t('messenger_connect_telegram')}
                </button>
            </a>
        </>
    );
}

export default MessengerActivation;