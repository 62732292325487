import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function ExtrasForm({ nextStep, prevStep, handleChange, formData }) {
  const { t } = useTranslation();
  const [selectedExtras, setSelectedExtras] = useState(formData.extras || []);

  const extras = ['elevator', 'balcony', 'terrace', 'garden', 'pool', 'storage', 'parking']; // Add more extras as needed

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedExtras([...selectedExtras, event.target.value]);
    } else {
      setSelectedExtras(selectedExtras.filter(extra => extra !== event.target.value));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    formData.extras = selectedExtras;
    nextStep();
  };

  return (
    <form className="my-4 px-4 w-full" onSubmit={handleSubmit}>
      <button onClick={prevStep} className="text-blueGray-500 font-medium text-sm py-1.5 mb-4">
        <i className="fas fa-arrow-left mr-1"></i> {t('form_back')}
      </button>
      <label className="block mb-4 text-xl font-semibold text-blueGray-700 dark:text-white">
        {t('form_question_extras')}
      </label>
      <div className='mb-6 w-10/12 grid grid-cols-2 gap-4'>
        {extras.map((extra, index) => (
          <div key={index} className="flex items-center pl-4 border border-blueGray-300 cursor-pointer rounded dark:border-gray-700">
            <input
              id={`bordered-checkbox-${index}`}
              type="checkbox"
              value={extra}
              name="bordered-checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor={`bordered-checkbox-${index}`}
              className="w-full py-4 ml-2 text-sm font-medium cursor-pointer text-gray-900 dark:text-gray-300"
            >
              {t(`extras_${extra}`)}
            </label>
          </div>
        ))}
      </div>

      <button type="submit" className="text-white bg-customBlue hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-4 py-1.5 mt-6 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        {t('form_extras_action')} <i className="fas fa-arrow-right ml-2"></i>
      </button>
    </form>
  );
}

export default ExtrasForm;